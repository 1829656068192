let env;
let trackingHost;
let tokenHost;
let orderHost;
const context = '/innovel-services/scheduler-service/api';
const tokenContext = '/innovel-services/rft/api';
let loginClient;
let trackingHome;
let alertHost;
let alertContext = '/innovel-services/alert'

env = process.env.REACT_APP_RFT_ENV || 'local';

switch (env) {
  case 'local':
    trackingHost = '';
    orderHost = '';
    tokenHost = '';
    loginClient = '';
    trackingHome = '/';
    alertHost = '';
    break;
  case 'prod':
    trackingHost = '';
    orderHost = '';
    tokenHost = '';
    loginClient = '';
    trackingHome = '/';
    alertHost = '';
    break;
  case 'nonprod':
    trackingHost = '';
    orderHost = '';
    tokenHost = '';
    loginClient = '';
    trackingHome = '/';
    alertHost = '';
      break;
  case 'qa':
    trackingHost = '';
    orderHost = '';
    tokenHost = '';
    loginClient = '';
    trackingHome = '/';
    alertHost = '';
      break;
  case 'perf':
    trackingHost = '';
    orderHost = '';
    tokenHost = '';
    loginClient = '';
    trackingHome = '/';
    alertHost = '';
      break;
  case 'dev':
    trackingHost = '';
    orderHost = '';
    tokenHost = '';
    loginClient = '';
    trackingHome = '/';
    alertHost = '';
    break;
  default:
    trackingHost = '';
    orderHost = '';
    tokenHost = '';
    alertHost = '';
}

export const API_ROOT = orderHost + context;
export const API_SERVER = trackingHost + '/innovel-services';
export const TOKEN_ROOT = tokenHost + tokenContext;
export const APP_CONTEXT = '/innovel-services/scheduler-service';
export const LOGIN_ROOT = loginClient + '/innovel-services/login/login';
export const TRACKING_ROOT = trackingHome;
export const ALERT_ROOT = alertHost + alertContext;
